var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('CRow',[_c('CCol',{attrs:{"col":"12","xl":"8"}},[_c('transition',{attrs:{"name":"slide"}},[_c('CCard',[_c('CCardHeader',[_vm._v(" Accounts ")]),_c('CCardBody',[_c('CAlert',{attrs:{"show":_vm.dismissCountDown,"color":"primary","fade":""},on:{"update:show":function($event){_vm.dismissCountDown=$event}}},[_vm._v(" ("+_vm._s(_vm.dismissCountDown)+") "+_vm._s(_vm.message)+" ")]),_c('CDataTable',{attrs:{"hover":"","striped":"","items":_vm.items,"fields":_vm.fields,"items-per-page":5,"pagination":""},scopedSlots:_vm._u([{key:"status",fn:function(ref){
var item = ref.item;
return [_c('td',[_c('CBadge',{attrs:{"color":_vm.statuses[item._status].color}},[_vm._v(_vm._s(_vm.statuses[item._status].name))])],1)]}},{key:"show",fn:function(ref){
var item = ref.item;
return [_c('td',[_c('CButton',{attrs:{"color":"secondary"},on:{"click":function($event){return _vm.showAccount(item.id)}}},[_vm._v("Show")])],1)]}},{key:"edit",fn:function(ref){
var item = ref.item;
return [_c('td',[_c('CButton',{attrs:{"color":"primary"},on:{"click":function($event){return _vm.editAccount(item.id)}}},[_vm._v("Edit")])],1)]}},{key:"delete",fn:function(ref){
var item = ref.item;
return [_c('td',[_c('CButton',{attrs:{"color":"danger"},on:{"click":function($event){return _vm.deleteAccount(item.id)}}},[_vm._v("Delete")])],1)]}}])})],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }